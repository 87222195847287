import React from "react";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import AccountBox from '@mui/icons-material/AccountBox';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
// import { AccountPicker } from "./AccountPicker";



/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    function handleLogout() {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }

    return (
        <div>
            <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit"
            >
                <AccountBox />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {/* <MenuItem onClick={() => handleAccountSelection()} key="switchAccount">Switch Account</MenuItem> */}
                <MenuItem></MenuItem>
                <MenuItem onClick={() => {} } key="logoutPopup">Settings</MenuItem>
                <MenuItem onClick={() => handleLogout("")} key="logoutRedirect">Logout</MenuItem>
            </Menu>
            {/* <AccountPicker open={accountSelectorOpen} onClose={handleClose} /> */}
        </div>
    );
}