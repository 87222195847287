import React from "react";
import { PageLayout } from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

function App() {
  return (
    <>
      <PageLayout>
        <AuthenticatedTemplate>
        </AuthenticatedTemplate>
      </PageLayout>


      <UnauthenticatedTemplate>
        <p>You are not signed in! Please sign in.</p>
      </UnauthenticatedTemplate>
      
    </>
  );
}

export default App;